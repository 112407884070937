
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {IntLen4,ValidateSQLInjection,mrp} from "../../../businessComponents/others/validations"
import fetchData from '../../others/fetchData';
import { useDispatch, useSelector } from 'react-redux';

const RbInventoryStock = () =>{

    const state = useSelector(state=>state)
    const { register, handleSubmit, formState: { errors }, trigger, setValue } = useForm();
    const [input,setinput] = useState({salesState:"0"})
    const dispatch = useDispatch()
    const stdDiv = {padding:'8px 0px 8px'}
    const stdDiv2 = {margin:'5px 0px 5px',width:'calc(100% - 20px)'}
    const [reqType,setreqType] = useState("add")
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);


    useEffect(()=>{

        if(state.rightBar.contents){
            setreqType("update")
            const validateInputs = async() =>{

                setinput(prev => (
                    {
                        ...prev, 
                        pid:state.rightBar.contents.pid,
                        did:state.rightBar.contents.did,
                        productName:state.rightBar.contents.productName,
                        productNameMarathi:state.rightBar.contents.productNameMarathi,
                        nonDiscountMrp:state.rightBar.contents.nonDiscountMrp,
                        mrp:state.rightBar.contents.mrp,
                        qnt:state.rightBar.contents.qnt,
                        qntType:state.rightBar.contents.qntType,
                        category:state.rightBar.contents.category,
                        salesCount:state.rightBar.contents.salesCount,
                        salesState:state.rightBar.contents.salesState
                    }))
                setValue('productName', state.rightBar.contents.productName);
                setValue('productNameMarathi', state.rightBar.contents.productNameMarathi);
                setValue('mrp', state.rightBar.contents.mrp);
                setValue('qnt', state.rightBar.contents.qnt);
                setValue('salesCount', state.rightBar.contents.salesCount);
                setValue('qntType', state.rightBar.contents.qntType);
                setValue('category', state.rightBar.contents.category);
                setValue('nonDiscountMrp', state.rightBar.contents.nonDiscountMrp);
                setValue('salesState', state.rightBar.contents.salesState);
                await trigger("productName");
                await trigger("productNameMarathi");
                await trigger("mrp");
                await trigger("qnt");
                await trigger("salesCount");
                await trigger("qntType");
                await trigger("category");
                await trigger("nonDiscountMrp");
                await trigger("salesState");
            }
            validateInputs()
                
        }

    },[state.rightBar,setValue, trigger])

    const [b,setb] = useState([])
    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        const getCategory = async () =>{
            var uri='api/be/v1.0/inventory/listCategory'
            var body={}
            const data = await fetchData(uri,body);
            try{
                setb(data.data.dbData)
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                setb(data)
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        getCategory()
    },[dispatch])

    const handlechange = async (e,type) =>{
        if (type==="productName"){
            setinput(prev => ({...prev, productName:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="productNameMarathi"){
            setinput(prev => ({...prev, productNameMarathi:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="nonDiscountMrp"){
            setinput(prev => ({...prev, nonDiscountMrp:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="mrp"){
            setinput(prev => ({...prev, mrp:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="qnt"){
            setinput(prev => ({...prev, qnt:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="salesCount"){
            setinput(prev => ({...prev, salesCount:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="category"){
            setinput(prev => ({...prev, category:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="qntType"){
            setinput(prev => ({...prev, qntType:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="salesState"){
            setinput(prev => ({...prev, salesState:e.target.value}))
            await trigger(e.target.name);
        }
    }  

    const changeType = ()=>{
        setreqType("add")
    }
    const onSubmit = (data) => {

        const putData = async (input,contents) =>{
            var uri='';
            var body={};
            if(reqType==="update"){
                uri='api/be/v1.0/inventory/editInventory'
                body={
                    type:'update',
                    data:input
                }
            }
            else{
                uri='api/be/v1.0/inventory/addInventory'
                body={
                    type:'add',
                    data:input
                }

            }
            await fetchData(uri,body)
            try{
                dispatch({type:'DIALOG_ON',title:`Success`,body:"Entry Updated",return:''})
                dispatch({type:'RIGHTBAR_OFF'})
            }
            catch(error){
                dispatch({type:'DIALOG_ON',title:`Error`,body:"There was some error in updating the entry",return:''})
            }
        }
        putData(input,state.rightBar.contents)
    }

    // File management
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    
        // Preview the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handleFileUpload = () => {
        if (!selectedFile) {
          console.log('No file selected.');
          return;
        }
    
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('data', JSON.stringify({ pid: state.rightBar.contents.pid, did:state.rightBar.contents.did }));
    
        fetch('https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch({type:'DIALOG_ON',body:"Image uploaded successfully",return:''})
            dispatch({type:'RIGHTBAR_OFF'})
          })
          .catch((error) => {
            console.error('Error uploading image:', error);
          });
    };

    const formHtml = () =>{
        return (
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={stdDiv}>
                    <div>Category</div>
                    <select onChange={(e)=>handlechange(e,'category')}  style={stdDiv2}>
                        <option value={input.category} selected>{input.category}</option>
                        {
                            b.map((item,i)=>(
                                <option key={i} value={item.cname}>{item.cname}</option>
                            ))
                        }
                    </select>
                    {errors.category && <div style={{color:'#F37512'}}>{errors.category.message}</div>}
                </div>

                {
                    reqType==="add" &&
                    <div style={stdDiv}>
                        <div>Product name</div>
                        <input 
                            type='text' name='productName'
                            {...register(
                                'productName',{required:"Mandatory",validate: ValidateSQLInjection}
                            )}
                            onKeyUp={(e)=>handlechange(e,'productName')} placeholder="Enter Product Name" style={stdDiv2}
                            defaultValue={input.productName}
                        />
                        {errors.productName && <div style={{color:'#F37512'}}>{errors.productName.message}</div>}
                    </div>
                }

                {
                    reqType==="add" &&
                    <div style={stdDiv}>
                        <div>Product name in marathi</div>
                        <input 
                            type='text' name='productNameMarathi'
                            {...register('productNameMarathi',{required:"Mandatory",validate: ValidateSQLInjection})}
                            onKeyUp={(e)=>handlechange(e,'productNameMarathi')} placeholder="Enter Name in Marathi" style={stdDiv2}
                            defaultValue={input.productNameMarathi}
                        />
                        {errors.productNameMarathi && <div style={{color:'#F37512'}}>{errors.productNameMarathi.message}</div>}
                    </div>
                }

                <div style={stdDiv}>
                    <div>MRP without default discounts</div>
                    <input 
                        type='text' name='nonDiscountMrp'
                        {...register('nonDiscountMrp',{required:"Mandatory",pattern:mrp,validate: ValidateSQLInjection})}
                        onKeyUp={(e)=>handlechange(e,'nonDiscountMrp')} placeholder="Enter MRP" style={stdDiv2}
                        defaultValue={input.nonDiscountMrp}
                    />
                    {errors.nonDiscountMrp && <div style={{color:'#F37512'}}>{errors.nonDiscountMrp.message}</div>}
                </div>

                {
                    reqType==="add" &&
                    <div style={stdDiv}>
                        <div>Maximum Retail Price (MRP)</div>
                        <input 
                            type='text' name='mrp'
                            {...register('mrp',{required:"Mandatory",pattern:mrp,validate: ValidateSQLInjection})}
                            onKeyUp={(e)=>handlechange(e,'mrp')} placeholder="Enter MRP" style={stdDiv2}
                            defaultValue={input.mrp}
                        />
                        {errors.mrp && <div style={{color:'#F37512'}}>{errors.mrp.message}</div>}
                    </div>
                }

                <div style={stdDiv}>
                    <div>Quantity Type</div>
                    <select onChange={(e)=>handlechange(e,'qntType')}  style={stdDiv2} >
                        <option value={input.qntType} selected>{input.qntType}</option>
                        <option value="L">L</option>
                        <option value="Dz">Dz</option>
                        <option value="Pc">Pc</option>
                        <option value="g">g</option>
                        <option value="kg">kg</option>
                        <option value="mL">mL</option>
                        <option value="cm">cm</option>
                        <option value="in">in</option>
                    </select>
                    {errors.qntType && <div style={{color:'#F37512'}}>{errors.qntType.message}</div>}
                </div>

                <div style={stdDiv}>
                    <div>Quantity</div>
                    <input 
                        type='text' name='qnt'
                        {...register('qnt',{required:"Mandatory",pattern:IntLen4,validate: ValidateSQLInjection})}
                        onKeyUp={(e)=>handlechange(e,'qnt')} placeholder="Enter Quantity" style={stdDiv2}
                        defaultValue={input.qnt}
                    />
                    {errors.qnt && <div style={{color:'#F37512'}}>{errors.qnt.message}</div>}
                </div>

                <div style={stdDiv}>
                    <div>Current Stock (Enter exact amount)</div>
                    <input 
                        type='text' name='salesCount'
                        {...register('salesCount',{required:"Mandatory"})}
                        onKeyUp={(e)=>handlechange(e,'salesCount')} placeholder="Enter Quantity" style={stdDiv2}
                        defaultValue={input.salesCount}
                    />
                    {errors.salesCount && <div style={{color:'#F37512'}}>{errors.salesCount.message}</div>}
                </div>

                <div style={stdDiv}>
                    <div>Status</div>
                    <select onChange={(e)=>handlechange(e,'salesState')}  style={stdDiv2}>
                        <option value={input.salesState} selected>{input.salesState === 1 ? "Enabled" : "Disabled"}</option>
                        {input.salesState!==1 && <option value="1">Enabled</option>}
                        {input.salesState!==0 && <option value="0">Disabled</option>}
                    </select>
                    {errors.salesState && <div style={{color:'#F37512'}}>{errors.salesState.message}</div>}
                </div>


                <button className="stdButton" onClick={handleSubmit(onSubmit)} style={{width:'100%'}}> Save </button>
                

                {
                    reqType==="update" &&
                    <div style={{margin:'5px 0px 5px', fontSize:'small',cursor:'pointer',textAlign:'justify'}}>Please note that cost once applied cannot be changed to maintain consistent financial records. If you want to change the cost of this product click below to create a new product, and you can manually disable the unrequired product.</div>
                }
                {
                    reqType==="update" &&
                    <div onClick={()=>changeType()} style={{color:'blue',margin:'5px 0px 5px',cursor:'pointer',textAlign:'center'}}>Add as a new product</div>
                }

            </form>
        )
    }

    return <div style={{textAlign:'left'}}>
            

    <div style={{padding:'10px'}}>

        {
            reqType==="update" &&
            <div style={{border:'0px solid red',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <h2 style={{margin:'0px 0px 10px'}}>{state.rightBar.contents.productName}</h2>
                {
                    previewUrl ?
                    <img alt="" src={previewUrl} style={{width:'150px',height:'258px'}}/>
                    :
                    <div>
                    {
                        state.rightBar.contents.image &&
                        <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_productImages/"+state.rightBar.contents.image} style={{width:'150px',height:'258px'}}/>
                    }
                    </div>
                }
                <input type="file" onChange={handleFileChange}  style={{width:'80%',margin:'10px'}}/>

                <button className="stdButton" onClick={handleFileUpload} style={{width:'100%'}}> Upload Image </button>

            </div>
        }

        {formHtml()}


    </div>

</div>
}

export default RbInventoryStock