import { useSelector } from "react-redux"

import OrdersCustomers from "./sublistings/ordersCustomers"
import LiBusinessAccount from "./sublistings/liBusinessAccount"

const BizListings = () =>{

    // Primary Definitions
    const state = useSelector(state => state)

    // Business Methods
    const listSelector = (list) =>{
        if(list === "orders_customers")
            return <OrdersCustomers/>
        else if(list === "businessAccount")
            return <LiBusinessAccount/>
    }

    return <div >
    
    {listSelector(state.listings)}
    </div>
}
export default BizListings