import { useSelector } from "react-redux"
import RBInventoryDelivery from "./subbody/rbInventoryDelivery"
import RbInventoryCategory from "./subbody/rbInventoryCategory"
import RbInventoryStock from "./subbody/rbInventorySock"

const BixRightbar = () =>{

    // Primary Definitions
    const state = useSelector(state => state)

    // Business Methods
    const listSelector = (list) =>{
        if(list === "Inventory")
            return <div><RbInventoryStock/></div>
        else if(list === "Delivery")
            return <div><RBInventoryDelivery/></div>
        else if(list === "Categories")
            return <div><RbInventoryCategory/></div>
        else
            return <div>Default</div>
    }

    return <div >
    
        {listSelector(state.rightBar.body)}
    </div>
}
export default BixRightbar