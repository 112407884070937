import { useState } from "react"
import { useSelector } from "react-redux"
import { FaUserCircle } from 'react-icons/fa';
import Logout from "./logout";

const Account = () =>{

    const state = useSelector(state => state)
    const [a,seta] = useState(false)


    const logoutNow =()=>{
        seta(true)
    }

    return <div style={{display:'flex',justifyContent:'center'}}>
        <div className="stdbox" style={{width:'420px',height:'490px',margin:'20px 0px 0px',padding:'0px'}}>

            <div style={{padding:'30px 0px 20px',fontSize:'150px',lineHeight:'0px',color:'#888'}}>
                <FaUserCircle/>
            </div>

            <div className="stdBorder" style={{borderLeft:'0px',borderRight:'0px',marginTop:'20px',padding:'10px 0px'}}>
                {state.loginData.name}
            </div>
            <div className="stdBorder" style={{borderLeft:'0px',borderRight:'0px',borderTop:'0px',padding:'10px 0px'}}>
                Username: {state.loginData.username}
            </div>
            <div className="stdBorder" style={{borderLeft:'0px',borderRight:'0px',borderTop:'0px',padding:'10px 0px'}}>
                Mobile: {state.loginData.mobile}
            </div>
            <div className="stdBorder" style={{borderLeft:'0px',borderRight:'0px',borderTop:'0px',padding:'10px 0px'}}>
                Email Address: {state.loginData.email}
            </div>
            <div className="stdBorder" style={{border:'0px',padding:'10px 0px'}}>
                Session ID
            </div>
            <div className="stdBorder" style={{borderLeft:'0px',borderRight:'0px',borderTop:'0px',padding:'0px 0px 15px',fontSize:'10px'}}>
                {state.loginData.sid}
            </div>
            <div className="stdBorder" 
                onClick={()=>logoutNow()}
                style={{borderLeft:'0px',borderRight:'0px',borderTop:'0px',borderBottom:'0px',padding:'10px 0px',cursor:'pointer',color:'BLUE'}}
            >
                Sign Out
            </div>
            {
                a && <Logout/>
            }
        </div>
    </div>
}

export default Account