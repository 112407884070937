import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MdDarkMode, MdWbSunny } from 'react-icons/md';

import { useForm } from 'react-hook-form';



const Settings = () =>{

    // Primary Definitions
    const state = useSelector((state)=>state);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();


    useEffect(()=>{
        console.log(state)
    },[state])
    const setTheme = (data) =>{
        dispatch({type:'CHANGETHEME',payload:data})
    }
    const onSubmit = (data) => {
        console.log(data);
    };
    const handleChange = async (event) => {
        await trigger(event.target.name);
    };
    
    return <div style={{width:'auto',border:'0px dashed WHITE',height:'auto', textAlign:'left',display:'flex'}}>
        <div style={{width:'650px',height:'100px',display:'flex',flexDirection:'column'}}>
            <div className='stdBackground'>
                <div><h3>Profile</h3></div>
                <div className="padTop">
                    Add settings contents here...
                </div>
            </div>
            <div className='stdBackground'>
                <div><h3>Password Reset</h3></div>
                <div className="padTop">
                    Ensure you use strong password with atleast 8 characters, at least 2 alphanumeric & special characters.
                </div>
                <div className="padTop">
                    <div style={{display:'flex',justifyContent:'left',marginBottom:'5px'}}>
                        <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Enter Password</div>
                        <input type="password" name="password"/>
                    </div>
                    <div style={{display:'flex',justifyContent:'left'}}>
                        <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Confirm Password</div>
                        <input type="password" name="password"/>
                    </div>
                </div>
            </div>

            <div className='stdBackground'>
                <div><h3>Profile Information</h3></div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="padTop">
                        <div style={{display:'flex',justifyContent:'left',marginBottom:'5px'}}>
                            <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Full Name</div>
                            <div style={{display:'flex',alignItems:'center',margin:'auto',flexDirection:'column'}}>
                                <input type="text" name="name" {...register('name', { required: true })} onKeyUp={handleChange}/>
                                {errors.name && <div className="error">This field is required</div>}
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'left',marginBottom:'5px'}}>
                            <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Email Address</div>
                            <div style={{display:'flex',alignItems:'center',margin:'auto',flexDirection:'column'}}>
                                <input type="text" name="emails" {...register('emails', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} onKeyUp={handleChange}/>
                                {errors.emails && <div className="error">This Meow is required</div>}
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'left',marginBottom:'5px'}}>
                            <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Mobile Number</div>
                            <div style={{display:'flex',alignItems:'center',margin:'auto',flexDirection:'column'}}>
                                <input type="text" name="mobile" {...register('mobile', { required: true, pattern: /^\d{10}$/ })} onKeyUp={handleChange}/>
                                {errors.mobile && <div className="error">Mobile number should be 10 digits</div>}
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'left',marginBottom:'5px'}}>
                            <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}>Address</div>
                            <select style={{display:'flex',alignItems:'center',margin:'auto'}}>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>

                        </div>
                        <div style={{display:'flex',justifyContent:'left',marginTop:'15px'}}>
                            <div style={{width:'150px',display:'flex',alignItems:'center',margin:'auto'}}></div>
                            <button className="buttonCss"> Save </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='stdBackground'>
                <div><h3>Theme</h3></div>
                <div className="padTop" style={{display:'flex'}}>

                    <div onClick={()=>setTheme(true)} className={state.lightTheme===true ? 'stdBorder stdBorderSelected':'stdBorder'} style={{padding:'10px',width:'70px',textAlign:'center',borderRadius:'10px',cursor:'pointer'}}>
                        <div style={{fontSize:'30px'}}>
                            <MdWbSunny/>
                        </div>
                        <div style={{fontSize:'12px'}}>Light Mode</div>
                    </div>

                    <div onClick={()=>setTheme(false)} className={state.lightTheme===false ? 'stdBorder stdBorderSelected':'stdBorder'}  style={{padding:'10px',width:'70px',marginLeft:'10px',textAlign:'center',borderRadius:'10px',cursor:'pointer'}}>
                        <div style={{fontSize:'30px'}}>
                            <MdDarkMode/>
                        </div>
                        <div style={{fontSize:'12px'}}>Dark Mode</div>
                    </div>

                </div>
            </div>
        </div>
        <div style={{width:'400px',height:'100px',display:'flex',flexDirection:'column'}}>
            <div className='stdBackground'>
                <div><h2>Settings</h2></div>
                <div className="padTop" style={{textAlign:'justify'}}>
                Welcome to our portal's settings module, where you can manage various aspects that affect your account. This module empowers you to customize and fine-tune your experience. It's important to note that certain changes may require you to log out and log back in, such as resetting your password. In such cases, please ensure you adhere to our strong password policy to enhance the security of your account. Safeguarding your information is our top priority, and we encourage you to choose a password that combines uppercase and lowercase letters, numbers, and special characters. Let's prioritize your account's security together!
                </div>
            </div>

        </div>
    </div>
}

export default Settings