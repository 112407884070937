
// Import Components
import { useSelector } from "react-redux"
import Settings from "./subbody/settings"
import Orders from "./subbody/orders"
import OrdersTimeline from "./subbody/ordersTimeline"
import OrdersBilling from "./subbody/ordersBilling"
import Account from "./subbody/account"
import Logout from "./subbody/logout"
import Dashboard from "./subbody/dashboard"
import Inventory from "./subbody/inventory"
import InventoryDelivery from "./subbody/inventoryDelivery"
import InventoryCategory from "./subbody/inventoryCategories"
import BusinessAccount from "./subbody/businessAccount"


const BizBody = () =>{

    // Primary Definitions
    const state = useSelector((state)=>state)

    //Business Methods
    const bodySelector = () =>{


        if((state.selectedMenu.name==="Orders")&&(state.selectedMenu.isMenu.name==="Overview"))
            return <Orders/>
        if((state.selectedMenu.name==="Orders")&&(state.selectedMenu.isMenu.name==="Tracking"))
            return <OrdersTimeline/>
        if((state.selectedMenu.name==="Orders")&&(state.selectedMenu.isMenu.name==="Billing"))
            return <OrdersBilling/>
        else if(state.selectedMenu.name==="Account")
            return <Account/>
        else if(state.selectedMenu.name==="Dashboard")
            return <Dashboard/>
        else if(state.selectedMenu.name==="Logout")
            return <Logout/>
        else if((state.selectedMenu.name==="Inventory")&&(state.selectedMenu.isMenu.name==="Inventory"))
            return <Inventory/>
        else if((state.selectedMenu.name==="Inventory")&&(state.selectedMenu.isMenu.name==="Delivery"))
            return <InventoryDelivery/>
        else if((state.selectedMenu.name==="Inventory")&&(state.selectedMenu.isMenu.name==="Categories"))
            return <InventoryCategory/>
        else if((state.selectedMenu.name==="Business")&&(state.selectedMenu.isMenu.name==="Account"))
            return <BusinessAccount/>
        else if(state.selectedMenu.name==="Settings")
            return <Settings/>
    }

    return <div className="bizBody" style={{padding:'0px',height:'500px'}}>
            {bodySelector()}
    </div>
}
export default BizBody