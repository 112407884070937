import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import BusinessInformation from "./business/businessInformation";
import BrandLogo from "./business/brandLogo";


const BusinessAccount = () =>{

    const dispatch = useDispatch();
    const state = useSelector(state=>state)

    useEffect(()=>{
        dispatch({type:'LIST',list:'businessAccount'})
    },[dispatch])

    return <div style={{width:'100%'}}>{
        state.body.length!==0 ?
        <div>
            {state.body.name==="Business Information" && <BusinessInformation/>}
            {state.body.name==="Brand Logo" && <BrandLogo/>}

        </div>
        :
        <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
            Please click settings
        </div>
    }
    </div>
}

export default BusinessAccount