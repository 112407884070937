import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"


const LiBusinessAccount = () =>{

    const dispatch = useDispatch();

    const [businessAccount,setbusinessAccount] = useState([])

    useEffect(()=>{
        setbusinessAccount([
            {
                id:1, name:"Business Information", icon:"BD",type:'GREEN'
            },
            {
                id:2, name:"Brand Logo", icon:"BD",type:'BLUE'
            }
        ])
        dispatch({type:'LISTINGS_ADD',payload:[
            {
                id:1, name:"Business Information", icon:"BD",type:'GREEN'
            },
            {
                id:2, name:"Brand Logo", icon:"BD",type:'BLUE'
            }
        ]})
    },[dispatch])
    const [selected,setselected] = useState()
    const clickItem = (item,index) =>{
        setselected(index)
        dispatch({type:"BODY_ADD",payload:item})
    }
    const icon = (item) =>{
        if(item.type==="BLACK"){
            // BLACK
            return (
                <div style={{
                    background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
        else if(item.type==="GREEN"){
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
        else if(item.type==="PINK"){
            // PINK
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
        else if(item.type==="BLUE"){
            // BLUE
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
        else if(item.type==="AMBER"){
            // AMBER
            return (
                <div style={{
                    background:'linear-gradient(195deg, #FF8D05, #DF7B03)',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
        else{
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.icon}</b>
                </div>
            )
        }
    }

    return <div>
        <div className="stdBorder" style={{borderTop:"0px",borderLeft:"0px",borderRight:"0px",height:'60px',display:'flex'}}>
            <div className="mainTitleIcons" style={{display:'flex',marginLeft:'20px',border:'0px dashed #666',width:'70%'}}>
                <h3>Settings</h3>
            </div>
        </div>
        <div className='scrollbarTypeDefault' style={{height:'calc(100vh - 61px)', overflow:'auto'}}>
            {
                businessAccount && businessAccount.length !==0 
                ?
                businessAccount.map((item,index)=>(
                    <div key={index} onClick={()=>clickItem(item,index)} className={ selected===index ? "list_item_selected" : "list_item"}>
                        {icon(item)}
                        <div 
                            style={{
                            display:'flex',margin:'auto 0px auto',flexDirection:'column',
                            width:'calc(100% - 90px)'
                        }}>
                            <div style={{display:'flex',alignItems:'end',border:'0px dashed #ccc',width:'100%',justifyContent:'flex-start'}}>
                                <b>{item.name}</b>
                            </div>
                        </div>
                    </div>
                ))
                :
                <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                    No data available
                </div>
            }
        </div>
    </div>
}

export default LiBusinessAccount