
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import fetchData from '../../../others/fetchData';
import Slider from '@mui/material/Slider';

const BrandLogo = () =>{

    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [c,setc] = useState([])

    useEffect(()=>{

        const updateData = async () =>{
            const uri='api/be/v1.0/inventory/getlogo';
            const body={}

            const data = await fetchData(uri,body)
            try{
                setc(data)
            }
            catch (error){

            }
        }
        updateData()

    },[])
    // File management
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    
        // Preview the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handleFileUpload = () => {
        if (!selectedFile) {
          console.log('No file selected.');
          return;
        }
    
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('data', JSON.stringify({ height: input}));
    
        fetch('https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_backgrounds', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch({type:'DIALOG_ON',body:"Image uploaded successfully",return:''})
            dispatch({type:'RIGHTBAR_OFF'})
          })
          .catch((error) => {
            console.error('Error uploading image:', error);
          });
    };
    const [input,setinput] = useState(80);
    const handleChange = (e) =>{
        setinput(e.target.value)
    }

    return <div style={{width:'100%',border:'0px dashed RED'}}>
        <h2 style={{padding:'20px 0px 20px'}}>Brand Logo</h2>

        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{padding:'20px 0px'}}>
                {
                    previewUrl ?
                    <img alt="" src={previewUrl} height={input+'px'} />
                    :
                    <div>
                    {
                        c && c.data && c.data.dbData && c.data.dbData.length!==0 &&
                        <img alt="" src={"https://api.98b870ed6919e4ffdd74527f19020ae9.laxab.com/api/upload_backgrounds/"+c.data.dbData[0].logo} height={input+'px'}/>
                    }
                    </div>
                }
            </div>
            {
                previewUrl &&
                <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" 
                    name="input" onChange={handleChange} min={20} max={90}
                    style={{width:'50%'}}
                />
            }
            
            <input type="file" onChange={handleFileChange}  style={{width:'50%',margin:'10px'}}/>

            <button className="stdButton" onClick={handleFileUpload} style={{width:'50%'}}> Upload Logo </button>

        </div>
    </div>
}

export default BrandLogo