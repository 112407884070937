
import { useSelector } from "react-redux"


const OrdersBilling = () =>{

    const state = useSelector(state=>state)

    return <div>
        {
            state.bodyContents.status !== "fail" 
            ?
            <div>
                <div className="stdbox_2" style={{borderRadius:'0px',margin:'20px 0px 0px',width:'800px'}}>
                    <div style={{margin:'20px 0px'}}>CASH MEMO</div>
                    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                        <div style={{width:'40%',border:'0px dashed #ccc',textAlign:'left'}}>
                            <div>BILLED TO</div>
                            <div>{state.bodyContents.data.orderCustomers[0].name}</div>
                            <div>{state.bodyContents.data.orderCustomers[0].address_line_1}</div>
                            <div>{state.bodyContents.data.orderCustomers[0].address_line_2}</div>
                            <div>Dist: {state.bodyContents.data.orderCustomers[0].district}, State: {state.bodyContents.data.orderCustomers[0].state}</div>
                            <div>PIN: {state.bodyContents.data.orderCustomers[0].pincode}</div>
                        </div>
                        <div style={{width:'40%',border:'0px dashed #ccc',textAlign:'left'}}>
                            <div>CONTACT DETAILS</div>
                            <div>Mobile: {state.bodyContents.data.orderCustomers[0].mobile}</div>
                            <div>Email: {state.bodyContents.data.orderCustomers[0].email}</div>
                        </div>
                        <div style={{width:'20%',border:'0px dashed #ccc',textAlign:'right'}}>
                            <div>BILL NUMBER: {state.bodyContents.data.orderCustomers[0].orderId}</div>
                        </div>
                    </div>
                </div>
                <div style={{margin:'0px'}}>
                    <table className="stdbox_2" style={{textAlign:'left',width:'100%',marginTop:'0px',borderRadius:'0px',padding:'0px 20px 0px'}}>
                        <tr >
                            <td style={{width:'calc(100% - 240px)'}}><b>Item</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>Quantity</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>MRP</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>Total MRP</b></td>
                        </tr>
                    </table>
                </div>
                {
                    state.bodyContents
                    &&
                    state.bodyContents.data
                    &&
                    state.bodyContents.data.orderItems
                    &&
                    state.bodyContents.data.orderItems.map((item,index)=>(
                        <div key={index} style={{margin:'0px'}}>
                            <table className="stdbox_2" style={{textAlign:'left',width:'100%',marginTop:'0px',borderRadius:'0px',padding:'0px 20px 0px'}}>
                                <tr >
                                    <td style={{width:'calc(100% - 240px)'}}>{item.productNameMarathi} / {item.productName}</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>{item.totalItems}</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>₹ {item.mrp}.00</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>₹ {item.totalMrp}.00</td>
                                </tr>
                            </table>
                        </div>
                    ))
                }
                <div style={{margin:'0px'}}>
                    <table className="stdbox_2" style={{textAlign:'left',width:'100%',marginTop:'0px',borderRadius:'0px',padding:'0px 20px 0px'}}>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',textAlign:'right',padding:'0px 20px 0px'}}>Order Total</td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}>₹ {state.body && state.bodyContents.data.orderCustomers[0].totalAmount && state.bodyContents.data.orderCustomers[0].totalAmount}.00</td>
                        </tr>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',textAlign:'right',padding:'0px 20px 0px'}}>Delivery Charge</td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}>₹ {state.body && state.bodyContents.data.orderCustomers[0].pindeliverycharge && state.bodyContents.data.orderCustomers[0].pindeliverycharge}.00</td>
                        </tr>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',textAlign:'right',padding:'0px 20px 0px'}}><b>Total Amount</b></td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}><b>₹ {state.body && state.bodyContents.data.orderCustomers[0].grandtotal && state.bodyContents.data.orderCustomers[0].grandtotal}.00</b></td>
                        </tr>
                    </table>
                </div>
                <div className="stdbox_2" style={{borderRadius:'0px',margin:'0px 0px 0px',paddingBottom:'20px',display:'flex',justifyContent:'center'}}>
                    <div style={{width:'30%',height:'auto',marginRight:'10px',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                        Stamp
                    </div>
                    <div  style={{width:'40%',height:'auto',margin:'0px',textAlign:'left',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                        <div>The amount is inclusive of all taxes</div>
                        <div>Kindly keep this bill handy for any refunds you may require</div>
                    </div>
                    <div className="stdBorder" style={{width:'30%',height:'auto',marginLeft:'10px',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                        Authorized Signature
                    </div>
                </div>
            </div>
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
            </div>
        }
    </div>
}

export default OrdersBilling