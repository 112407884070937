import { useSelector } from "react-redux"
import { FiFlag,FiSmile, FiTruck, FiDollarSign } from 'react-icons/fi';
import { IoIosTimer } from 'react-icons/io';


const OrdersTimeline = () =>{

    const state = useSelector(state=>state)


    const orderStageCheck = (orderTimeline, stage) =>{
        return orderTimeline.some(item => item.stage === stage);
    }
    const iconDisplay = (title) =>{
        if(title==="Order Placed by Customer")
            return <FiDollarSign style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="We are working on your order")
            return <FiSmile style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="Delivery Initiated")
            return <FiTruck style={{display:'flex',margin:'auto 0px auto'}}/>
        else if(title==="Delivery Confirmed!")
            return <FiFlag style={{display:'flex',margin:'auto 0px auto'}}/>
        else
            return <IoIosTimer style={{display:'flex',margin:'auto 0px auto'}}/>
    }
    const orderTracking = (state, title, body) =>{
        if(state)
            return(
                <div style={{margin:'10px 0px 0px',width:'800px'}}>
                    <div style={{background:'#fff',padding:'20px 200px 20px',display:'flex'}}>
                        <div style={{
                            width:'90px',height:'70px',display:'flex',justifyContent:'center',borderRadius:'10px',color:'#fff',fontSize:'30px',
                            background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                            boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                        }}>
                            {iconDisplay(title)}
                        </div>
                        <div style={{width:'calc(100% - 80px)',height:'auto',display:'flex',margin:'auto 0px auto 20px',justifyContent:'left'}}>
                            <div style={{display:'flex',margin:'auto 0px auto',flexDirection:'column',textAlign:'left'}}>
                                <b>{title}</b>
                                <div style={{color:"#999"}}>{body}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div style={{margin:'10px 0px 0px',width:'800px'}}>
                    <div style={{background:'#fff',padding:'20px 200px 20px',display:'flex'}}>
                        <div style={{width:'90px',height:'70px',background:'#eee',display:'flex',justifyContent:'center',borderRadius:'10px',color:'#888',fontSize:'30px'}}>
                            {iconDisplay(title)}
                        </div>
                        <div style={{width:'calc(100% - 80px)',height:'70px',display:'flex',margin:'auto 0px auto 20px',justifyContent:'left'}}>
                            <div style={{display:'flex',margin:'auto 0px auto', color:'#aaa',flexDirection:'column',textAlign:'left'}}>
                                <b>{title}</b>
                                {body}
                            </div>
                        </div>
                    </div>
                </div>
            )
    }

    return <div>


        {
            state.bodyContents.status !== "fail" 
            ?
            <div>
                <div style={{margin:'30px 0px 10px',padding:'10px',borderRadius:'8px'}}>
                    <h2>Order Tracking</h2>
                </div>
                <div style={{display:'flex',justifyContent:'center'}}><div style={{display:'flex',flexDirection:'column',lineHeight:'25px'}}>
                    {
                        state.bodyContents && state.bodyContents.data && state.bodyContents.data.orderTimeline &&
                        orderStageCheck(state.bodyContents.data.orderTimeline,0) 
                        &&
                        orderTracking(true, "Order Canceled", "Your order has been canceled. Your amount will be refunded soon.")
                    }
                    {
                        !orderStageCheck(state.bodyContents.data.orderTimeline,0) && <div>
                            {
                                state.bodyContents && state.bodyContents.data && state.bodyContents.data.orderTimeline &&
                                orderStageCheck(state.bodyContents.data.orderTimeline,4) 
                                ? 
                                orderTracking(true, "Delivery Confirmed!", "Your order has been delivered")
                                : 
                                orderTracking(false, "Delivery Confirmation", "Awaiting")

                            }
                        </div>
                    }
                    {
                        !orderStageCheck(state.bodyContents.data.orderTimeline,0) && 
                        <div>
                            {
                                state.bodyContents && state.bodyContents.data && state.bodyContents.data.orderTimeline &&
                                orderStageCheck(state.bodyContents.data.orderTimeline,3) 
                                ? 
                                orderTracking(true, "Delivery Initiated", 
                                    "Your order is in transit at your shipping address: "
                                    +state.bodyContents.data.orderCustomers[0].area+", "
                                    +state.bodyContents.data.orderCustomers[0].state+", PIN: "+state.bodyContents.data.orderCustomers[0].pincode
                                )
                                : 
                                orderTracking(false, "Delivery Initiation", "Awaiting")
                            }
                        </div>
                    }
                    {
                        !orderStageCheck(state.bodyContents.data.orderTimeline,0) && 
                        <div>
                            {
                                state.bodyContents && state.bodyContents.data && state.bodyContents.data.orderTimeline &&
                                orderStageCheck(state.bodyContents.data.orderTimeline,2) 
                                ? 
                                orderTracking(true, "We are working on your order", "Once your order is processed, we will initiate delivery")
                                : 
                                orderTracking(false, "Order Processing", "Awaiting")

                            }
                        </div>
                    }
                    {
                        state.bodyContents && state.bodyContents.data && state.bodyContents.data.orderTimeline &&
                        orderStageCheck(state.bodyContents.data.orderTimeline,1) 
                        ? 
                        orderTracking(true, "Order Placed by Customer", "Order placed by customer. Order ID "+state.bodyContents.data.orderTimeline.orderId)
                        : 
                        orderTracking(false, "Order Purchased by Customer", "Awaiting")
                    }
                </div></div>
            </div>
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
            </div>
        }
    </div>
}

export default OrdersTimeline