
import { useEffect, useState } from "react"
import fetchData from "../../others/fetchData"
import { useDispatch, useSelector } from "react-redux"
import { AiFillDelete } from 'react-icons/ai';


const InventoryDelivery = () =>{

    const dispatch = useDispatch();
    const state = useSelector(state=>state)
    const [a,seta] = useState([])

    useEffect(()=>{
        dispatch({type:'BACKDROP_ON'})
        const getInventory = async () =>{
            var uri = 'api/be/v1.0/inventory/listPincode';
            var body = {
                "contents": 'state.body'
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                seta(data)
            }
            catch (error) {
            }
        }
        getInventory()

        dispatch({type:'BACKDROP_ON'})
        const getCategory = async () =>{
            var uri='api/be/v1.0/inventory/listCategory'
            var body={}
            await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        getCategory()

    },[state.dialogbox,dispatch])
    const rightBarDisplay = () =>{
        dispatch({type:"RIGHTBAR_ON",title:"Add/Edit Delivery",body:"Delivery"})
    }
    const [pincheck,setpincheck]=useState()
    const [d,setd] = useState([])
    const handlePinCheck = (e) =>{
        setpincheck(e.target.value)
    }
    const submitPinCheck = () =>{
        dispatch({type:'BACKDROP_ON'})
        const apipincheck = async (pin) =>{
            const uri = 'api/be/v1.0/inventory/pincheck'
            const body = {"pincode":pin}
            const data = await fetchData(uri,body)
            try {setd(data);dispatch({type:'BACKDROP_OFF'})}
            catch (e){dispatch({type:'BACKDROP_OFF'})}
        }
        apipincheck(pincheck)
    }
    const deleteThis = (item) =>{
        
        dispatch({type:'BACKDROP_ON'})
        const deleteItem = async (zone) =>{
            const uri = 'api/be/v1.0/inventory/deletezone'
            const body = {"zone":zone}
            await fetchData(uri,body)
            try{
                dispatch({type:'DIALOG_ON',title:``,body:`Item ${zone} deleted`,return:''})
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                dispatch({type:'BACKDROP_OFF'})}
        }
        deleteItem(item.zone)
        
    }

    return <div style={{width:'100%'}}>
        {
            a.status==="success"
            ?
            <div style={{margin:'30px 30px',display:'flex',justifyContent:'center',width:'100%'}}>



                <div>

                    <div onClick={()=>rightBarDisplay()}  className="stdButton" style={{width:'100px',marginBottom:'20px'}}>Add New</div>

                    <div className="stdbox" style={{margin:'30px 0px 30px',background:'#fff',display:'flex',height:'200px',width:'1000px',padding:'0px',borderRadius:'0px'}}>
                        <div style={{width:'30%',border:'0px dashed RED',margin:'auto',justifyContent:'center'}}>
                            <h2>Test Pincode</h2>
                            <div>Enter pincode to get the visibility of applied costs</div>
                        </div>
                        <div style={{width:'30%',border:'0px dashed RED',margin:'auto',justifyContent:'center'}}>
                            <input value={pincheck} onChange={handlePinCheck} style={{width:'200px'}} type='text' name='test' placeholder="Enter Pincode" />
                            <button onClick={submitPinCheck} className="stdButton" style={{marginTop:'20px',width:'220px'}}>Search</button>
                        </div>
                        <div style={{width:'calc(40% - 0px)'}}>
                            <div className="stdbox_black_3" style={{overflow:'auto',width:'calc(100% - 40px)',textAlign:'left',height:'calc(100% - 40px)',padding:'20px'}}>
                                
                                <div>{pincheck==="" && "Pincode not entered"}</div>
                                
                                <pre>
                                    {d.status==="success" && d.data ? JSON.stringify(d.data,2,2) : "Invalid pincode"}
                                </pre>
                            </div>
                        </div>
                    </div>

                    <div style={{width:'1000px'}}>
                        <div style={{display:'flex',fontWeight:'bold',marginBottom:'5px'}}>
                            <div className="stdbox_3 bgTheme" style={{width:'30%',padding:'10px 22px 10px'}}>Zone Name</div>
                            <div className="stdbox_3 bgTheme" style={{width:'20%',padding:'10px 22px 10px'}}>State</div>
                            <div className="stdbox_3 bgTheme" style={{width:'20%',padding:'10px 22px 10px'}}>District</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>Pincode</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>Cost</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>Delete</div>
                        </div>
                    </div>
                    {
                        a.data.dbData.map((item,index) => (
                            <div key={index} style={{width:'100%'}}>
                                <div style={{display:'flex',cursor:'pointer'}}>
                                    <div className="stdbox_3" style={{width:'30%',margin:'1px'}} alt="Test">
                                        {
                                            item.zone.length>25 ? item.zone.substring(0,25)+"..." : item.zone
                                        }
                                    </div>
                                    <div className="stdbox_3" style={{width:'20%',margin:'1px'}}>{item.state}</div>
                                    <div className="stdbox_3" style={{width:'20%',margin:'1px'}}>{item.district}</div>
                                    <div className="stdbox_3" style={{width:'10%',margin:'1px'}}>{item.pincode}</div>
                                    <div className="stdbox_3" style={{width:'10%',margin:'1px'}}>{item.cost}</div>
                                    <div onClick={()=>deleteThis(item)} className="stdbox_3" style={{width:'10%',margin:'1px'}}><AiFillDelete style={{fontSize:'20px',color:'#aaa'}}/></div>
                                </div>
                            </div>
                        ))
                    }


                    <div style={{margin:'130px'}}></div>
                </div>
            </div>
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
            </div>
        }
        
    </div>
}

export default InventoryDelivery