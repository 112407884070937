import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import fetchData from "../../others/fetchData";



const Dashboard = () =>{

    const dispatch = useDispatch();

    const [a,seta] = useState([]);

    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/be/v1.0/dashboard/categories';
            var body = {
                "name": "Abhijit",
                "id": 1
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                seta(data)
                console.log(a)
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();
    },[dispatch,a])

    return <div style={{border:'0px dashed #666',width:'1000px',marginTop:'30px'}}>

        {/*}
        <div className="stdbox" style={{width:'300px',display:'flex',justifyContent:'center',padding:'0px'}}>
            <div style={{width:'100%'}}>
                <h2 style={{padding:'10px'}}>Categories</h2>
                <div className="stdBorder" style={{width:'100%',borderBottom:'0px',borderLeft:'0px',borderRight:'0px',display:'flex'}}>
                    <div style={{width:'60%',textAlign:'left',padding:'10px 20px 10px'}}>sd</div>
                    <div style={{width:'40%',textAlign:'right',padding:'10px 20px 10px'}}>10</div>
                </div>
                <pre style={{textAlign:'left'}}>
                    {JSON.stringify(a,2,2)}
                </pre>

            </div>
        </div>
        {*/}

    </div>
}

export default Dashboard