import { useEffect, useState } from "react"

//import fetchData from "../../others/fetchData" 
import { BsFilterSquare } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux"
import fetchData from "../../others/fetchData";

const OrdersCustomers = () =>{

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    // Business Methods

    const icon = (item) =>{
        if(item.orderStage==="Order Placed"){
            // BLACK
            return (
                <div style={{
                    background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
        else if(item.orderStage==="Delivered"){
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
        else if(item.orderStage==="Canceled"){
            // PINK
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
        else if(item.orderStage==="In Transit"){
            // BLUE
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
        else if(item.orderStage==="Work In Progress"){
            // AMBER
            return (
                <div style={{
                    background:'linear-gradient(195deg, #FF8D05, #DF7B03)',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
        else{
            // GREEN
            return (
                <div style={{
                    background:'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
                    boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
                    color:'#fff',padding:'0px 0px 4px',
                    display:'flex',alignItems:'center',margin:'auto 15px auto',width:'55px',height:'45px',borderRadius:'5px',justifyContent:'center'}}
                >
                    <b style={{fontSize:'23px',marginTop:'5px'}}>{item.orderId}</b>
                </div>
            )
        }
    }

    useEffect(()=>{
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/be/v1.0/orders/listall';
            var body = {
                "pageNumber":1,
                "pageSize":100
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'LISTINGS_ADD',payload:data.data.dbData})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();
        //dispatch({type:'DIALOG_ON',title:`Consent`,body:`This component contains end user's personal data. Please handle with care. All your actions will be logged for security and auditing purposes. By using this component, you agree to comply with privacy regulations and protect the confidentiality of user data.`,return:''})
    },[state.listings,dispatch])


    useEffect(()=>{
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/be/v1.0/orders/orderdetails';
            var body = {
                "orderId":state.body.orderId
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'BODYCONTENTS_ADD',payload:data})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();
    },[state.body,dispatch])

    const filterMenu =(data)=>{

        dispatch({type:'BACKDROP_ON'})
        const getData = async (filter) => {
            var uri = 'api/be/v1.0/orders/listall';
            var body = {
                "filter":filter,
                "pageNumber":1,
                "pageSize":100
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'LISTINGS_ADD',payload:data.data.dbData})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData(data);
    }

    const [selected,setselected] = useState()
    const clickItem = (item,index) =>{
        setselected(index)
        dispatch({type:"BODY_ADD",payload:item})
    }
    const [b,setb] = useState(false)
    const setFilter = (option) =>{
        if(option===1)
            setb(true)
        else
            setb(false)
    }

    return <div>
        <div className="stdBorder" style={{borderTop:"0px",borderLeft:"0px",borderRight:"0px",height:'60px',display:'flex'}}>
            <div className="mainTitleIcons" style={{display:'flex',marginLeft:'20px',border:'0px dashed #666',width:'70%'}}>
                <h3>Orders</h3>
            </div>
            <div onClick={()=>setFilter(1)} style={{display:'flex',marginTop:'auto',fontSize:'20px',paddingTop:'5px',cursor:'pointer',marginBottom:'auto',marginRight:'20px',border:'0px dashed #666',width:'30%',justifyContent:'flex-end'}}>
                <h3><BsFilterSquare/></h3>
                {
                    b
                    &&
                    <div onMouseLeave={()=>setFilter(0)} className="stdbox " style={{position:'absolute',padding:'0px',borderRadius:'0px',fontSize:'16px',width:'150px',textAlign:'left'}}>
                        <div onClick={()=>filterMenu('all')} style={{padding:'20px 18px 10px'}}>All Records</div>
                        <div onClick={()=>filterMenu('Order Placed')} style={{padding:'0px 18px 10px'}}>Order Placed</div>
                        <div onClick={()=>filterMenu('Work In Progress')} style={{padding:'0px 18px 10px'}}>WIP</div>
                        <div onClick={()=>filterMenu('In Transit')} style={{padding:'0px 18px 10px'}}>In Transit</div>
                        <div onClick={()=>filterMenu('Delivered')} style={{padding:'0px 18px 10px'}}>Delivered</div>
                        <div onClick={()=>filterMenu('Canceled')} style={{padding:'0px 18px 20px'}}>Canceled</div>
                    </div>
                }
            </div>
        </div>
        <div className='scrollbarTypeDefault' style={{height:'calc(100vh - 61px)', overflow:'auto'}}>
            {
                state.listingsContents && state.listingsContents.length && state.listingsContents.length !==0 
                ?
                state.listingsContents.map((item,index)=>(
                    <div key={index} onClick={()=>clickItem(item,index)} className={ selected===index ? "list_item_selected" : "list_item"}>
                        {icon(item)}
                        <div 
                            style={{
                            display:'flex',margin:'auto 0px auto',flexDirection:'column',
                            width:'calc(100% - 90px)'
                        }}>
                            <div style={{display:'flex',alignItems:'end',border:'0px dashed #ccc',width:'100%',justifyContent:'flex-start'}}>
                                <b>{item.name}</b>
                            </div>
                            <div style={{display:'flex',alignItems:'end',border:'0px dashed #ccc',fontSize:'small',width:'100%',justifyContent:'flex-end'}}>
                                <div style={{background:'#eee',padding:'3px 8px 3px',borderRadius:'5px',marginTop:'5px'}}>
                                    {
                                        item.area && item.area.length && item.area.length>10 ? item.area.substring(0, 9)+'..., '+item.district : item.area+", "+item.district}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                :
                <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                    No data available
                </div>
            }
        </div>
    </div>
}

export default OrdersCustomers