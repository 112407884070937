
import { useEffect, useState } from "react"
import fetchData from "../../others/fetchData"
import { useDispatch, useSelector } from "react-redux"
import { AiFillDelete } from 'react-icons/ai';

const InventoryCategory = () =>{

    const dispatch = useDispatch();
    const state = useSelector(state=>state)
    const [a,seta] = useState([])


    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        const getCategory = async () =>{
            var uri='api/be/v1.0/inventory/listCategory'
            var body={}
            const data = await fetchData(uri,body);
            try{
                seta(data)
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                seta(data)
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        getCategory()

    },[dispatch,state.dialogbox])
    const rightBarDisplay = (data) =>{
        if(data)
            dispatch({type:"RIGHTBAR_ON",title:"Edit Categories",body:"Categories",contents:data})
        else
            dispatch({type:"RIGHTBAR_ON",title:"Add Category",body:"Categories"})
    }
    const deleteThis = (item) =>{

        dispatch({type:'BACKDROP_ON'})
        const getCategory = async (cname) =>{
            var uri='api/be/v1.0/inventory/deleteCategory'
            var body={"cname":cname}
            const data = await fetchData(uri,body);
            try{
                if(data.status==="fail")
                    dispatch({type:'DIALOG_ON',body:data.message,return:''})
                else
                    dispatch({type:'DIALOG_ON',body:data.data,return:''})
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        getCategory(item.cname)
    }

    return <div style={{width:'100%'}}>
        {
            a.status==="success"
            ?
            <div style={{margin:'30px 30px',display:'flex',justifyContent:'center'}}>

                <div>
                    <div onClick={()=>rightBarDisplay()}  className="stdButton" style={{width:'100px',marginBottom:'20px'}}>Add New</div>

                    <div style={{width:'1000px'}}>
                        <div style={{display:'flex',fontWeight:'bold',marginBottom:'5px'}}>
                            <div className="stdbox_3 bgTheme" style={{width:'20%',padding:'10px 22px 10px'}}>Priority</div>
                            <div className="stdbox_3 bgTheme" style={{width:'60%',padding:'10px 22px 10px'}}>Category Name</div>
                            <div className="stdbox_3 bgTheme" style={{width:'20%',padding:'10px 22px 10px'}}>Delete</div>
                        </div>
                    </div>
                    {
                        a.data.dbData.map((item,index) => (
                            <div key={index} style={{width:'1000px'}}>
                                <div style={{display:'flex',cursor:'pointer'}}>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'20%',margin:'1px'}}>{item.priority}</div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'60%',margin:'1px'}}>{item.cname}</div>
                                    <div onClick={()=>deleteThis(item)}  className="stdbox_3" style={{width:'20%',margin:'1px'}}><AiFillDelete style={{fontSize:'20px',color:'#aaa'}}/></div>
                                </div>
                            </div>
                        ))
                    }
                    <div style={{margin:'130px'}}></div>
                </div>
            </div>
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
                <pre style={{textAlign:'left'}}>{JSON.stringify(a,2,2)}</pre>
            </div>
        }
        
    </div>
}

export default InventoryCategory