

import { useForm } from 'react-hook-form';
import { EmailValidation,ValidateSQLInjection,Mobile } from '../../../others/validations';
import { useState,useEffect } from 'react';
import fetchData from '../../../others/fetchData';
import { useDispatch } from 'react-redux';

const BusinessInformation = () =>{
    
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, trigger, setValue } = useForm();
    const [input,setinput] = useState([])
    const stdDiv2 = {margin:'5px 0px 5px',width:'calc(100% - 20px)'}

    useEffect(()=>{

        const updateData = async () =>{
            const uri='api/be/v1.0/inventory/getBusinessInfo';
            const body={}

            const data = await fetchData(uri,body)
            try{
                if(data.data)
                    if(data.data.dbData.length!==0){
                        setinput(data.data.dbData[0])
                        setValue('businessName', data.data.dbData[0].businessName);
                        setValue('businessAddress', data.data.dbData[0].businessAddress);
                        setValue('businessEmail', data.data.dbData[0].businessEmail);
                        setValue('relationsEmail', data.data.dbData[0].relationsEmail);
                        setValue('contact', data.data.dbData[0].contact);
                    }
            }
            catch (error){
            }
        }
        updateData()

    },[setValue])

    const handlechange = async (e,type) =>{

        if (type==="businessName"){
            setinput(prev => ({...prev, businessName:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="businessAddress"){
            setinput(prev => ({...prev, businessAddress:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="businessEmail"){
            setinput(prev => ({...prev, businessEmail:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="relationsEmail"){
            setinput(prev => ({...prev, relationsEmail:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="contact"){
            setinput(prev => ({...prev, contact:e.target.value}))
            await trigger(e.target.name);
        }
    }
    const onSubmit = (data) => {

        const updateData = async (input) =>{
            const uri='api/be/v1.0/inventory/businessInformation';
            const body={
                "input":input
            }

            const data = await fetchData(uri,body)
            try{
                if(data.status==="success")
                    dispatch({type:'DIALOG_ON',body:"Details uploaded successfully",return:''})
                else
                    dispatch({type:'DIALOG_ON',title:'Error',body:"Issues in updating",return:''})
            }
            catch(error){
                dispatch({type:'DIALOG_ON',title:'Error',body:"Issues in updating",return:''})
            }
        }
        updateData(input)
    }

    return <div style={{width:'100%',border:'0px dashed RED'}}>
        <h2 style={{padding:'20px 0px 20px'}}>Business Information</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div>
            <div style={{display:'flex',justifyContent:'center',height:'50px'}}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end',marginRight:'10px'}}>
                    <div style={{ marginTop:'auto',marginBottom:'auto', marginLeft: 'auto', alignSelf: 'end' }}>Business Name</div>
                </div>
                <div style={{width:'200px',display:'flex',justifyContent:'flex-start'}}>
                    <input 
                        type='text' name='businessName'
                        {...register(
                            'businessName',{required:"Business Name Mandatory",validate: ValidateSQLInjection}
                        )}
                        onKeyUp={(e)=>handlechange(e,'businessName')} placeholder="Enter Business Name" style={stdDiv2}
                        defaultValue={input.businessName}
                    />
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',height:'50px'}}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end',marginRight:'10px'}}>
                    <div style={{ marginTop:'auto',marginBottom:'auto', marginLeft: 'auto', alignSelf: 'end' }}>Business Address</div>
                </div>
                <div style={{width:'200px',display:'flex',justifyContent:'flex-start'}}>
                    <input 
                        type='text' name='businessAddress'
                        {...register(
                            'businessAddress',{required:"Business Address Mandatory",validate: ValidateSQLInjection}
                        )}
                        onKeyUp={(e)=>handlechange(e,'businessAddress')} placeholder="Enter Business Address" style={stdDiv2}
                        defaultValue={input.businessAddress}
                    />
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',height:'50px'}}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end',marginRight:'10px'}}>
                    <div style={{ marginTop:'auto',marginBottom:'auto', marginLeft: 'auto', alignSelf: 'end' }}>Official Business Email</div>
                </div>
                <div style={{width:'200px',display:'flex',justifyContent:'flex-start'}}>
                    <input 
                        type='text' name='businessEmail'
                        {...register(
                            'businessEmail',{required:"Official Business Email Mandatory",pattern: EmailValidation,validate: ValidateSQLInjection}
                        )}
                        onKeyUp={(e)=>handlechange(e,'businessEmail')} placeholder="Enter Business Email" style={stdDiv2}
                        defaultValue={input.businessEmail}
                    />
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',height:'50px'}}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end',marginRight:'10px'}}>
                    <div style={{ marginTop:'auto',marginBottom:'auto', marginLeft: 'auto', alignSelf: 'end' }}>Customer Relations Email</div>
                </div>
                <div style={{width:'200px',display:'flex',justifyContent:'flex-start'}}>
                    <input 
                        type='text' name='relationsEmail'
                        {...register(
                            'relationsEmail',{required:"Customer Relations Email Mandatory",pattern: EmailValidation,validate: ValidateSQLInjection}
                        )}
                        onKeyUp={(e)=>handlechange(e,'relationsEmail')} placeholder="Enter Relations Email" style={stdDiv2}
                        defaultValue={input.relationsEmail}
                    />
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',height:'50px'}}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end',marginRight:'10px'}}>
                    <div style={{ marginTop:'auto',marginBottom:'auto', marginLeft: 'auto', alignSelf: 'end' }}>Business Contact</div>
                </div>
                <div style={{width:'200px',display:'flex',justifyContent:'flex-start'}}>
                    <input 
                        type='text' name='contact'
                        {...register(
                            'contact',{required:"Business Contact Mandatory",pattern: Mobile,validate: ValidateSQLInjection}
                        )}
                        onKeyUp={(e)=>handlechange(e,'contact')} placeholder="Enter Relations Email" style={stdDiv2}
                        defaultValue={input.contact}
                    />
                </div>
            </div>
        </div>

        <button className="stdButton"  style={{width:'400px', margin:'10px 0px 10px'}}> Save </button>
        </form>
        <div>
            {errors.businessName && <div style={{color:'#F37512'}}>{errors.businessName.message}</div>}
            {errors.businessAddress && <div style={{color:'#F37512'}}>{errors.businessAddress.message}</div>}
            {errors.businessEmail && <div style={{color:'#F37512'}}>{errors.businessEmail.message}</div>}
            {errors.relationEmail && <div style={{color:'#F37512'}}>{errors.relationEmail.message}</div>}
            {errors.contact && <div style={{color:'#F37512'}}>{errors.contact.message}</div>}

        </div>
    </div>
}

export default BusinessInformation