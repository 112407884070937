
import { useEffect, useState } from "react"
import fetchData from "../../others/fetchData"
import { useDispatch, useSelector } from "react-redux"
import { HiBadgeCheck } from 'react-icons/hi';
import { MdDisabledByDefault } from 'react-icons/md';

const Inventory = () =>{

    const dispatch = useDispatch();
    const state = useSelector(state=>state)
    const [a,seta] = useState([])
    const [b,setb] = useState([])


    useEffect(()=>{

        dispatch({type:'BACKDROP_ON'})
        const getInventory = async () =>{
            var uri = 'api/be/v1.0/inventory/listInventory';
            var body = {
                "contents": 'state.body'
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                seta(data)
            }
            catch (error) {
            }
        }
        getInventory()

        dispatch({type:'BACKDROP_ON'})
        const getCategory = async () =>{
            var uri='api/be/v1.0/inventory/listCategory'
            var body={}
            const data = await fetchData(uri,body);
            try{
                setb(data.data.dbData)
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error){
                setb(data)
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        getCategory()

    },[dispatch,state.dialogbox])
    const rightBarDisplay = (data) =>{
        if(data)
            dispatch({type:"RIGHTBAR_ON",title:"Edit Inventory",body:"Inventory",contents:data})
        else
            dispatch({type:"RIGHTBAR_ON",title:"Add to Inventory",body:"Inventory"})
    }
    const handleOptionChange = (event) => {
        dispatch({type:'BACKDROP_ON'})
        var uri=''
        if(event.target.value==="All Categories"){
            uri='api/be/v1.0/inventory/listInventory'
        }
        else{
            uri='api/be/v1.0/inventory/listInventoryCategory'
        }
        const updateList = async (uri) =>{
            var body={"category":event.target.value}
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                seta(data)
                dispatch({type:'BACKDROP_OFF'})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
            }
        }
        updateList(uri)
    };

    return <div style={{width:'100%'}}>
        {
            a && a.status &&
            a.status==="success"
            ?
            <div style={{margin:'30px 30px',display:'flex',justifyContent:'center'}}>


                <div>
                    <div style={{marginBottom:'20px',display:'flex',height:'auto',justifyContent:'center'}}>

                        <div style={{display:'flex',margin:'auto 0px'}}>Select Category</div>
                        <div style={{display:'flex',margin:'auto 10px'}}>
                            <select onChange={handleOptionChange} style={{background:'#ddd'}}>
                                <option value="All Categories">All Categories</option>
                                {
                                    b.map((item,i)=>(
                                        <option key={i} value={item.cname}>{item.cname}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div onClick={()=>rightBarDisplay()}  className="stdButton" style={{width:'100px',marginBottom:'20px',display:'flex',margin:'auto 0px',justifyContent:'center'}}>Add New</div>
                    </div>

                    <div style={{width:'1000px'}}>
                        <div style={{display:'flex',fontWeight:'bold',marginBottom:'5px'}}>
                            <div className="stdbox_3 bgTheme" style={{width:'5%',padding:'10px 22px 10px'}}>ID</div>
                            <div className="stdbox_3 bgTheme" style={{width:'5%',padding:'10px 22px 10px'}}>Status</div>
                            <div className="stdbox_3 bgTheme" style={{width:'40%',padding:'10px 22px 10px'}}>Product Name</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>T-MRP</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>D-MRP</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>Quantity</div>
                            <div className="stdbox_3 bgTheme" style={{width:'10%',padding:'10px 22px 10px'}}>Stock</div>
                        </div>
                    </div>
                    {
                        a.data && a.data.dbData && a.data.dbData.map((item,index) => (
                            <div key={index} style={{width:'100%'}}>
                                <div  style={{display:'flex',cursor:'pointer'}}>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'5%',margin:'1px'}}>{item.pid}.{item.did}</div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'5%',margin:'1px'}}><div style={{display:'flex',justifyContent:'center',fontSize:'25px'}}>{item.salesState ===0 ? <MdDisabledByDefault style={{color:'red'}}/> : <HiBadgeCheck style={{color:'green'}}/>}</div></div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{display:'flex',width:'40%',margin:'1px'}}>
                                        
                                        <div style={{display:'flex',margin:'auto 0px'}}>{item.productName} / {item.productNameMarathi}</div>
                                    </div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'10%',margin:'1px'}}>₹{item.nonDiscountMrp.toFixed(2)}</div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'10%',margin:'1px'}}>₹{item.mrp.toFixed(2)}</div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'10%',margin:'1px'}}>{item.qnt}{item.qntType}</div>
                                    <div onClick={()=>rightBarDisplay(item)} className="stdbox_3" style={{width:'10%',margin:'1px'}}>{item.salesCount}</div>
                                </div>
                            </div>
                        ))
                    }
                    <div style={{margin:'130px'}}></div>
                </div>
            </div>
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
            </div>
        }
        
    </div>
}

export default Inventory