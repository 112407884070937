import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { MdPlayArrow, MdCancel } from 'react-icons/md';
import fetchData from "../../others/fetchData";
import { FaUserCircle } from 'react-icons/fa';


const Orders = () =>{

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    //const [a,seta] = useState([])

    useEffect(()=>{
        dispatch({type:'LIST',list:'orders_customers'})
    },[dispatch])

    const actionCancel = () =>{
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/be/v1.0/orders/actionCancel';
            var body = {
                "contents": state.body
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                //seta(data.data.listings.dbData)
                dispatch({type:'LISTINGS_ADD',payload:data.data.listings.dbData})
                dispatch({type:'BODYCONTENTS_ADD',payload:data})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();

    }
    const actionNext = () =>{
        dispatch({type:'BACKDROP_ON'})
        const getData = async () => {
            var uri = 'api/be/v1.0/orders/actionNext';
            var body = {
                "contents": state.body
            };
            const data = await fetchData(uri,body);
            try{
                dispatch({type:'BACKDROP_OFF'})
                //seta(data.data.listings.dbData)
                dispatch({type:'LISTINGS_ADD',payload:data.data.listings.dbData})
                dispatch({type:'BODYCONTENTS_ADD',payload:data})
            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'})
                dispatch({type:'CHANGE_BODY',payload:'Maintenance'})
            }
        };
        getData();
    }
    const checkAction = (currentStage) => {
        if(currentStage === "Delivered")
            return false
        if(currentStage === "Canceled")
            return false
        else return true

    }
    const nextStage = (currentStage) => {
        if(currentStage === "Order Placed")
            return "Work in Progress"
        else if(currentStage === "Work In Progress")
            return "In Transit"
        else if(currentStage === "In Transit")
            return "Delivered"
        else if(currentStage === "Delivered")
            return false
    }

    return<div style={{width:'100%',border:'0px dashed RED',display:'flex'}}>
        
        {
            state.bodyContents && 
            state.bodyContents.data && 
            state.bodyContents.data.length!==0 &&
            <div className='scrollbarTypeDefault' style={{width:'calc(100% - 350px)',height:'calc(100vh - 60px)'}}>


                <div style={{display:'flex',justifyContent:'center'}}>
                    <div className="stdbox" style={{margin:'20px 10px',height:'200px',width:'200px'}}>
                        <div style={{textAlign:'left'}}>
                            <div>Order ID</div>
                            <div style={{fontSize:'80px',lineHeight:'80px',color:'#333'}}><b>{state.bodyContents.data.orderCustomers[0].orderId}</b></div>
                        
                            <div style={{textAlign:'right',height:'90px',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                                <div>Status</div>
                                <div style={{fontSize:'large'}}><b>{state.bodyContents.data.orderCustomers[0].orderStage}</b></div>
                            </div>
                        </div>
                    </div>
                    <div className="stdbox" style={{margin:'20px 10px',height:'200px',width:'200px'}}>
                        <div style={{textAlign:'right'}}>{state.bodyContents.data.orderCustomers[0].pincode}</div>
                        <div style={{fontSize:'30px',height:'130px',border:'0px dashed #ccc',display:'flex'}}>
                            <div style={{display:'flex',textAlign:'left'}}><b>
                                {
                                    state.bodyContents.data.orderCustomers[0].area.length>30 ? state.bodyContents.data.orderCustomers[0].area.substring(0, 30)+"..." : state.bodyContents.data.orderCustomers[0].area
                                }
                            </b></div>
                        </div>
                        <div style={{height:'40px',border:'0px dashed #ccc',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                            <div style={{textAlign:'right'}}>
                            
                            {state.bodyContents.data.orderCustomers[0].district}, {state.bodyContents.data.orderCustomers[0].state}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="stdcolorblur" style={{display:'flex',justifyContent:'center',margin:'10px 0px',fontSize:'large'}}>
                    Actions
                </div>


                {
                    checkAction(state.bodyContents.data.orderCustomers[0].orderStage)
                    &&
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div onClick={()=>actionCancel()} className="stdbox_red_2" style={{display:'flex',justifyContent:'center',fontSize:'30px',borderRadius:'5px',width:'150px',padding:'10px 5px',lineHeight:'0px',margin:'0px 5px'}}>
                            <MdCancel/>
                            <div style={{display:'flex',margin:'auto 5px',fontSize:'15px'}}>Cancel Order</div>
                        </div>
                        <div onClick={()=>actionNext()} className="stdbox_green_2" style={{display:'flex',justifyContent:'center', fontSize:'30px',borderRadius:'5px',width:'150px',padding:'10px 5px',lineHeight:'0px',margin:'0px 5px'}}>
                            <div><MdPlayArrow/></div>
                            <div style={{display:'flex',margin:'auto 0px',fontSize:'15px'}}>{nextStage(state.bodyContents.data.orderCustomers[0].orderStage)}</div>
                        </div>
                    </div>

                }
                {
                    state.bodyContents.data.orderCustomers[0].orderStage==="Delivered" &&
                    <div className="stdcolorblur" style={{display:'flex',justifyContent:'center',margin:'10px 0px',fontSize:'large'}}>
                        <div className="stdbox_green" style={{padding:'10px 20px 10px',borderRadius:'10px'}}>Order has been delivered</div>
                    </div>
                }
                {
                    state.bodyContents.data.orderCustomers[0].orderStage==="Canceled" &&
                    <div className="stdcolorblur" style={{display:'flex',justifyContent:'center',margin:'10px 0px',fontSize:'large'}}>
                        <div className="stdbox_red" style={{padding:'10px 20px 10px',borderRadius:'10px'}}>Order has been canceled</div>
                    </div>
                }

                


                <div className="stdcolorblur" style={{display:'flex',justifyContent:'center',margin:'40px 0px 10px',fontSize:'large'}}>
                    Ordered Items
                </div>
                <div style={{margin:'0px'}}>
                    <table className="stdbox_2" style={{textAlign:'left',width:'100%',marginTop:'12px',borderRadius:'0px'}}>
                        <tr >
                            <td style={{width:'calc(100% - 240px)'}}><b>Item</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>Quantity</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>MRP</b></td>
                            <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}><b>Total MRP</b></td>
                        </tr>
                    </table>
                </div>
                {
                    state.bodyContents
                    &&
                    state.bodyContents.data
                    &&
                    state.bodyContents.data.orderItems
                    &&
                    state.bodyContents.data.orderItems.map((item,index)=>(
                        <div key={index} style={{margin:'0px'}}>
                            <table className="stdbox_2" style={{textAlign:'left',width:'100%',marginTop:'2px',borderRadius:'0px'}}>
                                <tr >
                                    <td style={{width:'calc(100% - 240px)'}}>{item.productNameMarathi} / {item.productName}</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>{item.totalItems}</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>₹ {item.mrp}.00</td>
                                    <td style={{width:'80px',border:'0px dashed RED',textAlign:'right'}}>₹ {item.totalMrp}.00</td>
                                </tr>
                            </table>
                        </div>
                    ))
                }
                <div style={{margin:'0px'}}>
                    <table className="stdbox_2" style={{textAlign:'left',width:'100%',margin:'2px 0px 80px',borderRadius:'0px'}}>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',padding:'10px',textAlign:'right'}}>Order Total</td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}>₹ {state.bodyContents.data.orderCustomers[0].totalAmount}.00</td>
                        </tr>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',padding:'10px',textAlign:'right'}}>Delivery Charge</td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}>₹ {state.bodyContents.data.orderCustomers[0].pindeliverycharge}.00</td>
                        </tr>
                        <tr>
                            <td style={{width:'calc(100% - 100px)',padding:'10px',textAlign:'right'}}><b>Total Amount</b></td>
                            <td style={{width:'100px',border:'0px dashed RED',textAlign:'right'}}><b>₹ {state.bodyContents.data.orderCustomers[0].grandtotal}.00</b></td>
                        </tr>
                    </table>
                </div>


                


            </div>
        }

        {
            state.bodyContents && 
            state.bodyContents.data && 
            state.bodyContents.data.length!==0 &&
            <div style={{
                width:'350px',height:'calc(100vh - 60px)',
                background:'linear-gradient(195deg, #eee, #fff)',
                boxShadow:'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem'
            }}>
                
                <div style={{padding:'20px 0px 20px',fontSize:'150px',lineHeight:'0px',color:'#ccc'}}>
                    <FaUserCircle/>
                </div>
                <div style={{padding:'10px 0px 30px',fontSize:'28px',lineHeight:'0px'}}>
                    <b>{state.bodyContents.data.orderCustomers[0].name}</b>
                </div>
                <div className="stdbox_2 stdBorder" style={{borderRadius:'0px',borderLeft:'0px',borderRight:'0px'}}>
                    <div style={{fontSize:'small',color:'#888'}}>Mobile</div>
                    <div>{state.bodyContents.data.orderCustomers[0].mobile}</div>
                </div>
                <div className="stdbox_2 stdBorder" style={{borderRadius:'0px',borderTop:'0px',borderLeft:'0px',borderRight:'0px'}}>
                    <div style={{fontSize:'small',color:'#888'}}>Email</div>
                    <div>{state.bodyContents.data.orderCustomers[0].email}</div>
                </div>
                <div className="stdbox_2 stdBorder" style={{borderRadius:'0px',borderTop:'0px',borderLeft:'0px',borderRight:'0px'}}>
                    <div style={{fontSize:'small',color:'#888'}}>Shipping Address</div>
                    <div style={{display:'flex',justifyContent:'center',margin:'10px 0px 0px',fontSize:'normal'}}>
                        {state.bodyContents.data.orderCustomers[0].address_line_1}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',margin:'10px 0px 0px',fontSize:'normal'}}>
                        {state.bodyContents.data.orderCustomers[0].address_line_2}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',margin:'10px 0px 0px',fontSize:'normal'}}>
                        {state.bodyContents.data.orderCustomers[0].area}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',margin:'10px 0px 0px',fontSize:'normal'}}>
                        District: {state.bodyContents.data.orderCustomers[0].district}, State: {state.bodyContents.data.orderCustomers[0].state}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',margin:'10px 0px 0px',fontSize:'normal'}}>
                        PIN: {state.bodyContents.data.orderCustomers[0].pincode}
                    </div>
                </div>

            </div>
        }

        {
            state.bodyContents.status === "fail" && 
            <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                No data available
            </div>
        }
    </div>
}

export default Orders